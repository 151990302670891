body { overflow: auto !important; }

.lightbox {
    margin: auto;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-items: center;
    width: 85vw;
    height: 85vh;
}

.img-lightbox {
    margin: auto;
    outline: none;
}

.zoomable {
    display: block;
    object-fit: contain;
    max-width: min(1400px, 80vw);
    max-height: min(1000px, 80vh);
}

.img-description {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px;
    background-color: rgba(15,16,12,.9);
}

.img-title {
    margin: 0;
    margin-right: auto;
    color: #E9ECEF;
    font-size: 1rem;
}

.img-media {
    margin: 0;
    margin-right: auto;
    color: #F8F9FA;
    font-size: .8rem;
}

.img-date {
    margin: 0;
    margin-right: auto;
    color: #F8F9FA;
    font-size: .8rem;
}

@media only screen and (orientation:portrait)  {

    .lightbox {
        height: auto;
    }

    .zoomable {
        min-height: 0vh;
        max-height: 60vh;
        max-width: 75vw;
        height: auto;
    }
}