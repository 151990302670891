@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);

#greeting {
    display: flex;
    justify-content: left;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    margin: -60px 0 0 0;
    padding: 0;
    z-index: 0;
}

.greeting-title-wrapper {
    margin: auto;
    margin-top: 85vh;
    display: flex;
    flex-direction: column;
}

.greeting-title {
    display: none;
    font-size: 2em;
    margin: auto;
    color: #E9ECEF;
}

#down-icon {
    display: none;
    font-size: 2.4em;
    margin: auto;
    margin-top: 5px;
}

#toTheTop {
    z-index: 10;
    font-size: 2.4rem;
    background-color: rgba(15, 16, 12, .8);
    border-radius: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    margin-bottom: 10px;
}

#top-button {
    color: #E9ECEF;
    position: relative;
    z-index: 20;
}

.MuiAccordion-root {
    box-shadow: none !important;
}