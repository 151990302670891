.hidden #contact {
    display: none;
}

#contact {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

#contacts-holder {
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    width: 100%;
    max-width: 600px;
}

.link-wrap {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 5px;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
    margin: 20px auto;
}

.link-wrap {
    display: flex; flex-direction: column;
    justify-content: space-between;
    width: auto;
    padding: 5px;
    margin-left: auto;
    margin-right: 20px;
}

.contact-element {
    display: flex; flex-direction: row;
    align-items: center;
    margin-right: auto;
    z-index: 4;
}

.contact-icon {
    padding-right: 10px;
}

.external-link {
    color: #212529;
    text-decoration: none;
    z-index: 4;
}
.commissions {
    padding-bottom: 10px;
}

.commissions {
    color: #212529;
    text-decoration: none;
    margin: auto;
    z-index: 4;
}

.menu-item:hover,
.external-link:hover {
    color: #ADB5BD;
    transition: color .2s;
}

.commissions {
    display: flex; flex-direction: column;
    padding-bottom: 10px;
}

@media only screen and (max-width: 650px) {
    /* .contact-element {
        margin: 0;
        margin-right: auto;
    } */
}